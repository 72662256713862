import {
  CREATE_HOME_FAIL,
  CREATE_HOME_REQUEST,
  CREATE_HOME_SUCCESS,
  FETCH_HOME_FAIL,
  FETCH_HOME_REQUEST,
  FETCH_HOME_SUCCESS,
  SHOW_HOME_FAIL,
  SHOW_HOME_REQUEST,
  SHOW_HOME_SUCCESS,
  UPDATE_HOME_FAIL,
  UPDATE_HOME_REQUEST,
  UPDATE_HOME_SUCCESS,
} from "./HomeType";

export const createHomeReducer = (
  state = { loading: false, home: [], error: "" },
  action
) => {
  switch (action.type) {
    case CREATE_HOME_REQUEST:
      return {
        loading: true,
      };
    case CREATE_HOME_SUCCESS:
      return {
        loading: false,
        userAuth: action.payload,

        error: "",
      };
    case CREATE_HOME_FAIL:
      return {
        loading: false,
        userAuth: [],

        error: "",
      };
    default:
      return state;
  }
};

export const getHomeReducer = (
  state = { loading: true, homes: [], error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_HOME_REQUEST:
      return {
        loading: true,
      };
    case FETCH_HOME_SUCCESS:
      return {
        loading: false,
        homes: action.payload,

        error: "",
      };
    case FETCH_HOME_FAIL:
      return {
        loading: false,
        userAuth: [],

        error: "",
      };
    default:
      return state;
  }
};

export const showHomeReducer = (
  state = { loading: true, home: [], error: "" },
  action
) => {
  switch (action.type) {
    case SHOW_HOME_REQUEST:
      return {
        loading: true,
      };
    case SHOW_HOME_SUCCESS:
      return {
        loading: false,
        home: action.payload,

        error: "",
      };
    case SHOW_HOME_FAIL:
      return {
        loading: false,
        userAuth: [],

        error: "",
      };
    default:
      return state;
  }
};

export const updateHomeReducer = (
  state = { loading: false, homes: [], error: "" },
  action
) => {
  switch (action.type) {
    case UPDATE_HOME_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_HOME_SUCCESS:
      return {
        loading: false,
        userAuth: action.payload,

        error: "",
      };
    case UPDATE_HOME_FAIL:
      return {
        loading: false,
        userAuth: [],

        error: "",
      };
    default:
      return state;
  }
};
