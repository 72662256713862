import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showStaffAction } from './StaffAction';
import { useParams } from 'react-router-dom';

const ShowStaffPage = ({ lang }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { staff } = useSelector(state => state.showStaff);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    dispatch(showStaffAction(id));
  }, [dispatch, id]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="flex flex-col pb-10">
      <img
        src={staff?.image}
        className="object-top object-cover staffimage mt-auto cursor-pointer"
        alt=""
        onClick={openModal}
      />
      <div className="text-right mt-10 md:px-20">
        <p className="font-bold text-4xl">{lang === "krd" ? staff?.krd_title : staff?.ar_title}</p>
        <p className="text-xl mt-3">{lang === "krd" ? staff?.krd_about : staff?.ar_about}</p>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
          <div className="relative">
            <img
              src={staff?.image}
              className="object-contain max-h-screen"
              alt=""
            />
            <button
              onClick={closeModal}
              className="absolute top-2 right-2 bg-white text-black rounded-full p-2 hover:bg-gray-200 transition duration-300"
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShowStaffPage;
