import { CREATE_CONTACT_FAIL, CREATE_CONTACT_REQUEST, CREATE_CONTACT_SUCCESS, FETCH_CONTACT_FAIL, FETCH_CONTACT_REQUEST, FETCH_CONTACT_SUCCESS, UPDATE_CONTACT_FAIL, UPDATE_CONTACT_REQUEST, UPDATE_CONTACT_SUCCESS } from "./ContactTypes";

  export const createContactReducer = (
    state = { loading: false, contact: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case CREATE_CONTACT_REQUEST:
        return {
          loading: true,
        };
      case CREATE_CONTACT_SUCCESS:
        return {
          loading: false,
          userAuth: action.payload,
  
          error: "",
        };
      case CREATE_CONTACT_FAIL:
        return {
          loading: false,
          userAuth: [],
  
          error: "",
        };
      default:
        return state;
    }
  };
  
  export const getContactReducer = (
    state = { loading: true, contacts: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case FETCH_CONTACT_REQUEST:
        return {
          loading: true,
        };
      case FETCH_CONTACT_SUCCESS:
        return {
          loading: false,
          contacts: action.payload,
  
          error: "",
        };
      case FETCH_CONTACT_FAIL:
        return {
          loading: false,
          userAuth: [],
  
          error: "",
        };
      default:
        return state;
    }
  };
  
  export const updateContactReducer = (
    state = { loading: false, contacts: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case UPDATE_CONTACT_REQUEST:
        return {
          loading: true,
        };
      case UPDATE_CONTACT_SUCCESS:
        return {
          loading: false,
          userAuth: action.payload,
  
          error: "",
        };
      case UPDATE_CONTACT_FAIL:
        return {
          loading: false,
          userAuth: [],
  
          error: "",
        };
      default:
        return state;
    }
  };
  