export const FETCH_HOME_REQUEST = "FETCH_HOME_REQUEST";
export const FETCH_HOME_SUCCESS = "FETCH_HOME_SUCCESS";
export const FETCH_HOME_FAIL = "FETCH_HOME_FAIL";

export const CREATE_HOME_REQUEST = "CREATE_HOME_REQUEST";
export const CREATE_HOME_SUCCESS = "CREATE_HOME_SUCCESS";
export const CREATE_HOME_FAIL = "CREATE_HOME_FAIL";

export const UPDATE_HOME_REQUEST = "UPDATE_HOME_REQUEST";
export const UPDATE_HOME_SUCCESS = "UPDATE_HOME_SUCCESS";
export const UPDATE_HOME_FAIL = "UPDATE_HOME_FAIL";

export const SHOW_HOME_REQUEST = "SHOW_HOME_REQUEST";
export const SHOW_HOME_SUCCESS = "SHOW_HOME_SUCCESS";
export const SHOW_HOME_FAIL = "SHOW_HOME_FAIL";