import {
  CREATE_STAFF_FAIL,
  CREATE_STAFF_REQUEST,
  CREATE_STAFF_SUCCESS,
  FETCH_STAFF_FAIL,
  FETCH_STAFF_REQUEST,
  FETCH_STAFF_SUCCESS,
  SHOW_STAFF_FAIL,
  SHOW_STAFF_REQUEST,
  SHOW_STAFF_SUCCESS,
  UPDATE_STAFF_FAIL,
  UPDATE_STAFF_REQUEST,
  UPDATE_STAFF_SUCCESS,
} from "./StaffType";

export const createStaffReducer = (
  state = { loading: false, staff: [], error: "" },
  action
) => {
  switch (action.type) {
    case CREATE_STAFF_REQUEST:
      return {
        loading: true,
      };
    case CREATE_STAFF_SUCCESS:
      return {
        loading: false,
        userAuth: action.payload,

        error: "",
      };
    case CREATE_STAFF_FAIL:
      return {
        loading: false,
        userAuth: [],

        error: "",
      };
    default:
      return state;
  }
};

export const getStaffReducer = (
  state = { loading: true, staffs: [], error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_STAFF_REQUEST:
      return {
        loading: true,
      };
    case FETCH_STAFF_SUCCESS:
      return {
        loading: false,
        staffs: action.payload,

        error: "",
      };
    case FETCH_STAFF_FAIL:
      return {
        loading: false,
        userAuth: [],

        error: "",
      };
    default:
      return state;
  }
};

export const showStaffReducer = (
  state = { loading: true, staff: [], error: "" },
  action
) => {
  switch (action.type) {
    case SHOW_STAFF_REQUEST:
      return {
        loading: true,
      };
    case SHOW_STAFF_SUCCESS:
      return {
        loading: false,
        staff: action.payload,

        error: "",
      };
    case SHOW_STAFF_FAIL:
      return {
        loading: false,
        userAuth: [],

        error: "",
      };
    default:
      return state;
  }
};

export const updateStaffReducer = (
  state = { loading: false, staffs: [], error: "" },
  action
) => {
  switch (action.type) {
    case UPDATE_STAFF_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_STAFF_SUCCESS:
      return {
        loading: false,
        userAuth: action.payload,

        error: "",
      };
    case UPDATE_STAFF_FAIL:
      return {
        loading: false,
        userAuth: [],

        error: "",
      };
    default:
      return state;
  }
};
