export const FETCH_ABOUT_REQUEST = "FETCH_ABOUT_REQUEST";
export const FETCH_ABOUT_SUCCESS = "FETCH_ABOUT_SUCCESS";
export const FETCH_ABOUT_FAIL = "FETCH_ABOUT_FAIL";

export const CREATE_ABOUT_REQUEST = "CREATE_ABOUT_REQUEST";
export const CREATE_ABOUT_SUCCESS = "CREATE_ABOUT_SUCCESS";
export const CREATE_ABOUT_FAIL = "CREATE_ABOUT_FAIL";

export const UPDATE_ABOUT_REQUEST = "UPDATE_ABOUT_REQUEST";
export const UPDATE_ABOUT_SUCCESS = "UPDATE_ABOUT_SUCCESS";
export const UPDATE_ABOUT_FAIL = "FETCH_ABOUT_FAIL";
