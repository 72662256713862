// import { api, loginApi } from "../Api/api";
// import { api, loginApi } from "../../../Api/api";
import { api, loginApi } from "../../Api/api";
import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT_SUCCESS,
} from "../Types/loginType";

const loginAction = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });
    const response = await loginApi.post("login", { email, password });
    const { status, data } = response;

    if (status == 200) {
      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data.userInfo,
      });
      console.log(response)
      getToken(data.token);
      sessionStorage.setItem("token", data.token);
    } else if (status == 401) {
      // console.log(response);
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: [],
      });
    }
  } catch (error) {
    
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: [],
      error: "Wrong Credentials, Please Try Again...",
    });
    if (error.response.status == 401) {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: [],
        error: "Wrong Credentials, Please Try Again...",
      });
    }
  }
};

export default loginAction;

export const getToken = (token) => {
  // return "7|XLkMhc8uyMoAO2uKD3sjULws3nz8hFiGcbB9uXNR410914e2";
  return "18|Ae6eRF9f98d25wEvJnEsETLu27RC69yBeP3mNDftc0daba46";
};

export const logoutAction = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    await api
      .post("user/logout", {}, config)
      .then((response) => {
        const { status } = response;
        if (status == 200) {
          dispatch({ type: USER_LOGOUT_SUCCESS });
          sessionStorage.removeItem("token");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {}
};
