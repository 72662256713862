import React, { useEffect } from "react";
import Sidebar from "./Components/Pages/Bars/Sidebar";
import "./App.css";
import Home from "./Components/Pages/Home/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import About from "./Components/Pages/About/About";
import ContactPage from "./Components/Pages/ContactPage/ContactPage";
import StaffPage from "./Components/Pages/Staff/StaffPage";
import Navbar from "./Components/Pages/Bars/Navbar";
import "./i18n";

import { useDispatch, useSelector } from "react-redux";

import ShowStaffPage from "./Components/Pages/Staff/ShowStaffPage";
import { FETCH_LANG_SUCCESS } from "./Store/store";
import i18n from "./i18n";
const App = () => {
  const { lang } = useSelector((state) => state.langReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    // console.log()

    let lang = sessionStorage.getItem("lang");

    sessionStorage.setItem("lang", lang);

    i18n.changeLanguage(
      lang === "krd"
        ? "kr"
        : lang === "ar"
        ? "ar"
        : lang === null || lang === undefined || lang ==="" || lang ==="null"
        ? "kr"
        : "kr"
    );
    if(lang === null || lang === undefined || lang ==="" || lang ==="null") lang ='krd';
    dispatch({
      type: FETCH_LANG_SUCCESS,
      payload: lang,
    });
  }, []);
  return (
    <div className="flex  h-screen  overflow-x-hidden">
      <BrowserRouter>
        <Navbar />
        <div className="  w-full overflow-x-hidden ">
          <Routes>
            <Route path="/" element={<Home lang={lang} />} />
            <Route path="/about" element={<About lang={lang} />} />
            <Route
              path="/contact"
              element={<ContactPage visable={true} lang={lang} />}
            />
            <Route
              path="/staff"
              element={<StaffPage isVisable={true} lang={lang} />}
            />
            <Route
              path="/show/staff/:id"
              element={<ShowStaffPage lang={lang} />}
            />
          </Routes>
        </div>

        <Sidebar />
      </BrowserRouter>
    </div>
  );
};

export default App;
