import React from "react";
import backgroundImage from "../../Assets/back.jpg";
import { motion, useAnimation, useScroll } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { getAboutAction } from "./AboutAction";
import { useDispatch, useSelector } from "react-redux";
const About = ({lang}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAboutAction());
  }, [lang]);

  const { abouts } = useSelector((state) => state.getAbout);
  const { t } = useTranslation();
  // Define animation states with duration
  const container = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
        duration: 0.5, // Duration of the container animation
      },
    },
  };

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5, // Duration of each item animation
      },
    },
  };
  
  return (
    <React.Fragment>
      {abouts == undefined || abouts?.length <= 0 ? (
        ""
      ) : (
        <motion.div
          className="relative  bg-cover h-screen bg-center"
          variants={container}
          initial="hidden"
          animate="visible"
          style={{
            backgroundImage: `url(${abouts[0]?.image})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
       
          <div className="absolute   inset-0 bg-slate-700 font-cali bg-opacity-50 backdrop-blur-sm flex items-center justify-center">
            <div className="text-center w-11/12 lg:w-8/12 text-white p-4">
              <motion.p
                initial={{
                  opacity: 0,
                  transition: {
                    duration: 0.5,
                  },
                  y: -100,
                }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 1,
                  },

                  y: 0,
                }}
                className=" md:text-2xl"
              > 

                {lang==="krd"?abouts[0]?.krd_text:abouts[0]?.ar_text}
              </motion.p>
            </div>
          </div>
        </motion.div>
      )}
    </React.Fragment>
  );
};

export default About;
