import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

import About from "../About/About";
import FirstPage from "../FirstPage/FirstPage";
import Location from "../Location/Location";
import ContactPage from "../ContactPage/ContactPage";
import StaffPage from "../Staff/StaffPage";
import { Link } from "react-router-dom";

const Home = ({lang}) => {
  const [firstPageRef, firstPageInView] = useInView();
  const [aboutRef, aboutInView] = useInView();
  const [contactPageRef, contactPageInView] = useInView();
  const [staffPageRef, staffPageInView] = useInView();
  

  const animationDuration = 1; // Adjust the duration here
  console.log("lang : ",lang)
  return (
    <div className=" h-full md:h-screen ">
      <motion.div
        ref={firstPageRef}
        initial={{ opacity: 0, y: 50 }}
        animate={firstPageInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: animationDuration }}
      >
        <FirstPage lang={lang}  />
      </motion.div>

      <motion.div
        ref={aboutRef}
        initial={{ opacity: 0, y: 50 }}
        animate={aboutInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: animationDuration }}
      >
        <About lang={lang} />
      </motion.div>

      <motion.div
        ref={staffPageRef}
        initial={{ opacity: 0, y: 50 }}
        animate={staffPageInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: animationDuration }}
      >
        <StaffPage isVisible={false} lang={lang} />
      </motion.div>

      <motion.div
        ref={contactPageRef}
        initial={{ opacity: 0, y: -100 }}
        animate={
          contactPageInView ? { opacity: 1, y: 0 } : { opacity: 0, y: -100 }
        }
        transition={{ duration: animationDuration }}
      >
        <ContactPage visible={false} lang={lang}  />
      </motion.div>

      <Location lang={lang} />

      <footer className=" left-0 w-full pb-32 md:pb-0 py-4 bg-[#1B1B1B]  text-white text-center">
        <Link to={"https://www.facebook.com/bryar.jalal?mibextid=ZbWKwL"}>
          Designed By : BJ
        </Link>
      </footer>
    </div>
  );
};

export default Home;
