import React from "react";
import { Link, NavLink } from "react-router-dom";
import otaLogo from "../../Assets/ota.png";
import { useTranslation } from "react-i18next";
import LanguageDropdown from "./LanguageDropdown ";
// import LanguageDropdown from "./LanguageDropdown";

const Sidebar = () => {
  const { t } = useTranslation();

  const handleScrollToTop = () => {
    window.location.href = '/';
  };

  return (
    <div className="hidden lg:flex lg:flex-col justify-evenly w-[17rem] h-screen bg-[#fcb315] text-black px-4 shadow-lg">
      <Link to="/" onClick={handleScrollToTop}>
        <img
          src={otaLogo}
          alt="OTA"
          className="h-64 w-[85%] mx-auto"
        />
      </Link>
      <ul className="text-lg text-right flex flex-col space-y-4">
        <NavLink
          exact
          to="/"
          className={({ isActive }) => (isActive ? "active" : "link")}
        >
          {t('sidebar.home')}
        </NavLink>
        <NavLink
          to="/about"
          className={({ isActive }) => (isActive ? "active" : "link")}
        >
          {t('sidebar.about')}
        </NavLink>
        <NavLink
          to="/staff"
          className={({ isActive }) => (isActive ? "active" : "link")}
        >
          {t('sidebar.staff')}
        </NavLink>
        <NavLink
          to="/contact"
          className={({ isActive }) => (isActive ? "active" : "link")}
        >
          {t('sidebar.contact')}
        </NavLink>
      </ul>
      <LanguageDropdown onClick={() => {}} />
    </div>
  );
};

export default Sidebar;
