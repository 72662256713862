import React from "react";
import { useTranslation } from "react-i18next";
import logo from "../../Assets/ota.png"; // Update with your logo path
import { motion } from "framer-motion";
import { useEffect } from "react";
import { getContactAction } from "./ContactAction";

import { useDispatch, useSelector } from "react-redux";
const Location = ({ lang='krd' }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getContactAction());
  }, []);


  const { contacts } = useSelector((state) => state.getContact);
  // console.log("dsaf ",contacts)
  return (
    <>
      {contacts == undefined || contacts?.length <= 0 ? (
        ""
      ) : (
        <div className="bg-[#1B1B1B]  md:pb-12   overflow-hidden text-white place-items-center justify-items-center grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 p-10">
          {/* Left Section: Logo and Company Name */}
          <motion.div
            className="flex items-center space-x-4 overflow-hidden"
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
          >
            <img src={logo} alt="Vigo Company Logo" className="h-28 md:h-32" />
          </motion.div>

          {/* Center Section: Contact Information */}
          <motion.div
            className="flex flex-col justify-center items-center"
            initial={{ opacity: 0, y: -100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <div className="text-center">
              <h2 className="text-lg mb-2">{t("location.title")}</h2>
              <p className="">
                {lang == "krd"
                  ? contacts[0]?.krd_address
                  : contacts[0]?.ar_address}
              </p>
              <h2 className="text-lg mb-2 mt-4">{t("location.contact")}</h2>
              <p>+{contacts[0]?.phone1}</p>
              <p>+{contacts[0]?.phone2}</p>

              <p>{contacts[0]?.email}</p>

            </div>
          </motion.div>

          {/* Right Section: Google Map */}
          <div className="mt-2 w-full">
            <iframe
              src={contacts[0]?.location}
              allowfullscreen=""
              width={"100%"}
              className="rounded-md"
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      )}
    </>
  );
};

export default Location;
