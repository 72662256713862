import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import otaLogo from "../../Assets/ota.png";
import Hamburger from "hamburger-react";
import { motion, AnimatePresence } from "framer-motion";
// import LanguageDropdown from "./LanguageDropdown";
import { useTranslation } from "react-i18next";
import LanguageDropdown from "./LanguageDropdown ";
import {useDispatch} from 'react-redux'
const Navbar = () => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const { t } = useTranslation();
  const dispatch=useDispatch()

  const toggleNavbar = () => setIsNavbarOpen(!isNavbarOpen);
  const closeNavbar = () => setIsNavbarOpen(false);

  return (
    <div>
      {/* Navbar for mobile and tablet */}
      <div className="lg:hidden z-50 fixed top-0 left-0 w-full bg-[#fcb315] text-black shadow-lg">
        <div className="flex items-center justify-between px-3 py-2">
       <a href="/">   <img src={otaLogo} alt="OTA" className="h-auto w-10 object-contain" /></a>
          <button onClick={toggleNavbar} className="text-2xl">
            <Hamburger hideOutline={false} toggled={isNavbarOpen} toggle={setIsNavbarOpen} />
          </button>
        </div>

        <AnimatePresence>
          {isNavbarOpen && (
            <motion.div
              initial={{ opacity: 0, maxHeight: 0, y: -20 }}
              animate={{ opacity: 1, maxHeight: 500, y: 0 }}
              exit={{ opacity: 0, maxHeight: 0, y: -20 }}
              transition={{ duration: 0.3, ease: "easeInOut" }}
              className="flex flex-col items-end p-4 space-y-4 overflow-hidden"
            >
              <NavLink
                exact
                to="/"
                className={({ isActive }) =>
                  isActive ? "bg-yellow-600 text-white p-2 rounded" : "hover:bg-yellow-300 p-2 rounded"
                }
                onClick={closeNavbar}
              >
                {t('navbar.home')}
              </NavLink>
              <NavLink
                to="/about"
                className={({ isActive }) =>
                  isActive ? "bg-yellow-600 text-white p-2 rounded" : "hover:bg-yellow-300 p-2 rounded"
                }
                onClick={closeNavbar}
              >
                {t('navbar.about')}
              </NavLink>
              <NavLink
                to="/staff"
                className={({ isActive }) =>
                  isActive ? "bg-yellow-600 text-white p-2 rounded" : "hover:bg-yellow-300 p-2 rounded"
                }
                onClick={closeNavbar}
              >
                {t('navbar.staff')}
              </NavLink>
              <NavLink
                to="/contact"
                className={({ isActive }) =>
                  isActive ? "bg-yellow-600 text-white p-2 rounded" : "hover:bg-yellow-300 p-2 rounded"
                }
                onClick={closeNavbar}
              >
                {t('navbar.contact')}
              </NavLink>
              <LanguageDropdown onClick={closeNavbar} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Navbar;
