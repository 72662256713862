import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import backgroundImage from "../../Assets/background.png";
import { useDispatch, useSelector } from "react-redux";
import { getHomeAction } from "./HomeAction";

const FirstPage = () => {
  const controls = useAnimation();
  const { t } = useTranslation();

  // Define animation states with duration
  const container = {
    hidden: { opacity: 1, scale: 0.5 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
        duration: 1, // Duration of the container animation
      },
    },
  };

  const item = {
    hidden: { y: 20, opacity: 1 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5, // Duration of each item animation
      },
    },
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getHomeAction());
  }, []);
  const { homes } = useSelector((state) => state.getHome);
  console.log(homes)
  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      className="h-screen  text-white  overflow-x-hidden"
    >
      <motion.div
        className="h-full z-50 flex flex-col justify-evenly"
        initial="hidden"
        animate="visible"
        variants={container}
      >
        <div className="flex  flex-col items-center">
          <motion.h1
            className="text-3xl  md:text-4xl  my-4 " // Ensure no extra space between letters
            variants={item}
            animate={controls}
          >
            {t("company")}
          </motion.h1>
          <motion.h2
            className="text-4xl lg:text-6xl title  my-2 text-center  text-[#fcb315] " // Ensure no extra space between letters
            variants={item}
            animate={controls}
          >
            {t("omar_taifur_ahmed")}
          </motion.h2>
          <motion.p
            className="text-lg md:text-3xl title my-2   " // Ensure no extra space between letters
            variants={item}
            animate={controls}
          >
            {t("for_general_trading_and_decorations")}
          </motion.p>
          <motion.p
            className="text-xl title md:text-3xl my-2  " // Ensure no extra space between letters
            variants={item}
            animate={controls}
          >
            {t("individual_project")}
          </motion.p>
        </div>

        <div className="text-center">
          <motion.p
            className="  text-lg title md:text-3xl my-2  " // Ensure no extra space between letters
            variants={item}
            animate={controls}
          >
            {t("company_representation")}
          </motion.p>

          <motion.div
            className="flex  justify-center md:flex  md:justify-center items-center gap-5 md:gap-48 my-6"
            variants={item}
            animate={controls}
          >
            {homes?.map((home, index) => {
              return (
                <Link
                key={index}
                  to={home?.link}
                >
                  <img
                    src={home.image}
                    alt="OIVA"
                    className="lg:h-16  h-10  mx-2"
                  />
                </Link>
              );
            })}
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default FirstPage;
