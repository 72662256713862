// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translation files

import arTranslation from './../src/Components/locales/ar/translation.json';
import krTranslation from './../src/Components/locales/kr/translation.json';

// Initialize i18next
i18n
  .use(initReactI18next)
  .init({
    resources: {
    //   en: { translation: enTranslation },
      ar: { translation: arTranslation },
      kr: { translation: krTranslation },
    },
    lng: 'kr' , // Set default language to Kurdish (Kurmanji)
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
