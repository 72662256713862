import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";


import { useTranslation } from "react-i18next";
import { FaEarthAsia } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import { FETCH_LANG_SUCCESS } from "../../../Store/store";
const LanguageDropdown = ({ onClick }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [lang, setLang] = useState("کوردی");
  const dropdownRef = useRef(null);
  const { i18n } = useTranslation();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Close dropdown if clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  const dispatch = useDispatch();

  return (
    <div className="relative">
      <button
        onClick={toggleDropdown}
        className="flex flex-row-reverse items-center justify-between w-full p-2 rounded bg-[#fca500] hover:bg-[#fca100] focus:outline-none"
      >
        <span className="font-semibold">{lang}</span>
        {/* < /> */}

        <FaEarthAsia
          className={`ml-2 transition-transform duration-700 ${
            isDropdownOpen ? "rotate-180" : ""
          }`}
        />
      </button>
      {isDropdownOpen && (
        <div
          ref={dropdownRef}
          className="relative right-0 mt-2 w-full bg-white border border-gray-200 rounded shadow-lg z-50"
        >
          <Link
            // to="/"
            className="block px-4 py-2 text-black hover:bg-gray-100"
            onClick={() => {
              setIsDropdownOpen(false);
              setLang("کوردی");
              sessionStorage.setItem("lang","krd")
              dispatch({ type: FETCH_LANG_SUCCESS, payload: sessionStorage.getItem("lang") });
              onClick();
              i18n.changeLanguage("kr");
            }}
          >
            کوردی
          </Link>
          <Link
            // to="/"
            className="block px-4 py-2 text-black hover:bg-gray-100"
            onClick={() => {
              setIsDropdownOpen(false);
              setLang("عربی");
              onClick();
              sessionStorage.setItem("lang","ar")
              dispatch({ type: FETCH_LANG_SUCCESS, payload: sessionStorage.getItem("lang")});
              i18n.changeLanguage("ar");
            }}
          >
            عربي
          </Link>
        </div>
      )}
    </div>
  );
};

export default LanguageDropdown;
