import Swal from "sweetalert2";
// import { api } from "../../Api/api";
// import { getToken } from "../Login/Action/login";
import {
  CREATE_ABOUT_FAIL,
  CREATE_ABOUT_REQUEST,
  CREATE_ABOUT_SUCCESS,
  FETCH_ABOUT_FAIL,
  FETCH_ABOUT_REQUEST,
  FETCH_ABOUT_SUCCESS,
  UPDATE_ABOUT_FAIL,
  UPDATE_ABOUT_REQUEST,
  UPDATE_ABOUT_SUCCESS,
} from "./AboutTypes";
import { getToken } from "../../Login/Action/login";
import { api } from "../../Api/api";

export const createAboutAction =
  (krdText, arText, image) => async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    const formData = new FormData();
    formData.append("krdText", krdText);
    formData.append("arText", arText);
    formData.append("image", image);
    dispatch({
      type: CREATE_ABOUT_REQUEST,
      payload: [],
    });
    try {
      const response = await api.post("about/create", formData, config);
      const { status, data } = response;

      if (status == 201) {
        dispatch({
          type: CREATE_ABOUT_SUCCESS,
          payload: data,
        });
        dispatch(getAboutAction());
        dispatch(getAboutAction());
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      //   console.log(error);
      dispatch({
        type: CREATE_ABOUT_FAIL,
        payload: [],
      });
    }
  };

export const getAboutAction = () => async (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  dispatch({
    type: FETCH_ABOUT_REQUEST,
    payload: [],
  });
  try {
    const response = await api.get("about", config);
    const { status, data } = response;

    if (status == 200) {
      dispatch({
        type: FETCH_ABOUT_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    //   console.log(error);
    dispatch({
      type: FETCH_ABOUT_FAIL,
      payload: [],
    });
  }
};

export const updateAboutAction =
  (id, krdText, arText, image) => async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    const formData = new FormData();
    formData.append("krdText", krdText);
    formData.append("arText", arText);
    formData.append("image", image);
    dispatch({
      type: UPDATE_ABOUT_REQUEST,
      payload: [],
    });
    try {
      const response = await api.post(`about/update/${id}`, formData, config);
      const { status, data } = response;
      console.log(response)
      if (status == 200) {
        dispatch(getAboutAction());
        dispatch(getAboutAction());
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been updated",
          showConfirmButton: true,
          timer: 1500,
        });
        dispatch({
          type: UPDATE_ABOUT_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
      //   console.log(error);
      dispatch({
        type: UPDATE_ABOUT_FAIL,
        payload: [],
      });
    }
  };
