import {
  CREATE_ABOUT_FAIL,
  CREATE_ABOUT_REQUEST,
  CREATE_ABOUT_SUCCESS,
  FETCH_ABOUT_FAIL,
  FETCH_ABOUT_REQUEST,
  FETCH_ABOUT_SUCCESS,
} from "./AboutTypes";

export const createAboutReducer = (
  state = { loading: false, about: [], error: "" },
  action
) => {
  switch (action.type) {
    case CREATE_ABOUT_REQUEST:
      return {
        loading: true,
      };
    case CREATE_ABOUT_SUCCESS:
      return {
        loading: false,
        userAuth: action.payload,

        error: "",
      };
    case CREATE_ABOUT_FAIL:
      return {
        loading: false,
        userAuth: [],

        error: "",
      };
    default:
      return state;
  }
};

export const getAboutReducer = (
  state = { loading: true, abouts: [], error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_ABOUT_REQUEST:
      return {
        loading: true,
      };
    case FETCH_ABOUT_SUCCESS:
      return {
        loading: false,
        abouts: action.payload,

        error: "",
      };
    case FETCH_ABOUT_FAIL:
      return {
        loading: false,
        userAuth: [],

        error: "",
      };
    default:
      return state;
  }
};

export const updateAboutReducer = (
  state = { loading: false, abouts: [], error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_ABOUT_REQUEST:
      return {
        loading: true,
      };
    case FETCH_ABOUT_SUCCESS:
      return {
        loading: false,
        userAuth: action.payload,

        error: "",
      };
    case FETCH_ABOUT_FAIL:
      return {
        loading: false,
        userAuth: [],

        error: "",
      };
    default:
      return state;
  }
};
