export const FETCH_STAFF_REQUEST = "FETCH_STAFF_REQUEST";
export const FETCH_STAFF_SUCCESS = "FETCH_STAFF_SUCCESS";
export const FETCH_STAFF_FAIL = "FETCH_STAFF_FAIL";

export const CREATE_STAFF_REQUEST = "CREATE_STAFF_REQUEST";
export const CREATE_STAFF_SUCCESS = "CREATE_STAFF_SUCCESS";
export const CREATE_STAFF_FAIL = "CREATE_STAFF_FAIL";

export const UPDATE_STAFF_REQUEST = "UPDATE_STAFF_REQUEST";
export const UPDATE_STAFF_SUCCESS = "UPDATE_STAFF_SUCCESS";
export const UPDATE_STAFF_FAIL = "FETCH_STAFF_FAIL";

export const SHOW_STAFF_REQUEST = "SHOW_STAFF_REQUEST";
export const SHOW_STAFF_SUCCESS = "SHOW_STAFF_SUCCESS";
export const SHOW_STAFF_FAIL = "SHOW_STAFF_FAIL";